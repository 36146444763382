import { Fragment, h } from 'preact'
import {
  BasicDialog,
  SearchList,
  SearchListItem,
  SelectChip,
  SpacerVertical
} from '@sodra/bongo-ui'

import { useState } from 'preact/hooks'
import { useMedia } from '@sodra/use-media'

const languages = [
  'English',
  'German',
  'Spanish',
  'Spanish - Mexican',
  'Italian',
  'Japanese',
  'Korean',
  'Polish',
  'Portugese - Brazilian',
  'Arabic',
  'Russian',
  'Chinese - Traditional',
  'Chinese - Simplified'
]

const LanguageSelectChip = ({ value, onChange }) => {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const isNarrow = useMedia(['(max-width: 600px)'], [true], false)

  const [showDialog, setShowDialog] = useState(false)
  const [query, setQuery] = useState('')

  const filtered = languages.filter((language) =>
    language.toLocaleLowerCase().startsWith(query.toLocaleLowerCase())
  )

  const handleSelect = (language) => {
    onChange(language)
    onClose()
  }

  const onClose = () => {
    setShowDialog(false)
    setQuery('')
  }

  return (
    <Fragment>
      <SelectChip
        label="Language"
        value={value}
        onClick={() => setShowDialog(true)}
        onClear={() => onChange()}
      />
      {showDialog && (
        <BasicDialog
          disableAutofocus={!supportsHover}
          full={isNarrow}
          large={true}
          title="Select language"
          onClose={onClose}
        >
          <SearchList query={query} onQueryUpdate={setQuery} onSelect={handleSelect}>
            {filtered.map((language) => {
              return (
                <SearchListItem
                  text={language}
                  value={language}
                  onClick={() => handleSelect(language)}
                />
              )
            })}
          </SearchList>
          <SpacerVertical large />
        </BasicDialog>
      )}
    </Fragment>
  )
}

export default LanguageSelectChip

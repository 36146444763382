import { get } from '../api'
import { store } from '../store'

export * from './player'

export const setError = (error) => store.setState({ error })

export const clearError = () => setError(null)

export const fetchVoice = async (voiceId) => {
    store.setState({ isFetchingVoice: true })
    try {
      const { data: voice } = await get(`/voices/${voiceId}`)
      store.setState({ voice, isFetchingVoice: false })
      return voice
    } catch (error) {
      store.setState({ error, isFetchingVoice: false })
      console.error(error)
    }
  }
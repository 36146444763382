import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { SearchIcon, TextField } from '@sodra/bongo-ui'

const SearchField = ({ value, onChange }) => {
  const [tmpQuery, setTmpQuery] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(tmpQuery)
    }, 500)
    return () => clearTimeout(timeout)
  }, [tmpQuery])

  return (
    <TextField
      autocomplete="off"
      simple
      clearable
      width="100%"
      icon={SearchIcon}
      label="Search voice talents"
      value={tmpQuery}
      onInput={setTmpQuery}
      onChange={onChange}
    />
  )
}

export default SearchField

import { Fragment, h, render } from 'preact'
import { Provider } from 'unistore/preact'

import { store } from './store'
import { Voices } from './App/Voices'

import './index.css'
import { Route, Router, Switch } from '@sodra/prutt'
import { Voice } from './App/Voice'
import Error from './App/Error'
import Player from './App/Player'

render(
  <Provider store={store}>
    <Fragment>
      <Router>
        <Switch>
          <Route path="/voice/:voiceId" component={Voice} />
          <Voices />
        </Switch>
      </Router>
      <Error />
      <Player />
    </Fragment>
  </Provider>,
  document.querySelector('body')
)

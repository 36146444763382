import {
  List,
  ListItem,
  PauseIcon,
  PlayIcon,
  SelectChip,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { Block, Inline, Row } from 'jsxstyle/preact'
import { Fragment, h } from 'preact'
import { useEffect } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { fetchVoice, togglePlay } from '../../actions'

import Avatar from '../Avatar'
import Logo from '../Logo'
import Spinner from '../Spinner'

export const Voice = connect('voice, player')(({ voice, voiceId, player }) => {
  useEffect(() => {
    fetchVoice(voiceId)
  }, [])

  if (!voice) {
    return <Spinner />
  }

  return (
    <Block padding="20px">
      <Block margin="20px auto" maxWidth="800px">
        <Logo onClick={() => routeTo('/')} />
        <SpacerVertical large />

        <Row alignItems="center">
          <Avatar size="100" src={voice.picture} name={voice.name} />
          <SpacerHorizontal />
          <Block>
            <Block fontSize="20px">{voice.name}</Block>
            {voice.gender && voice.yearOfBirth && (
              <Fragment>
                <SpacerVertical tiny />
                <Block color="var(--on-surface-light)">
                  <Inline textTransform="capitalize">{voice.gender}</Inline>,{' '}
                  {new Date().getFullYear() - voice.yearOfBirth}
                </Block>
              </Fragment>
            )}
          </Block>
        </Row>

        <SpacerVertical large />

        {voice.languages && (
          <Fragment>
            <Block fontSize="14px" color="var(--on-surface-light)">
              Languages
            </Block>
            <SpacerVertical />
            <Row gap={10} flexWrap="wrap">
              {voice.languages.map((lang) => {
                let value = lang.language
                if (lang.accent) {
                  value += ` (${lang.accent})`
                }
                return <SelectChip label={value} />
              })}
            </Row>
          </Fragment>
        )}

        <SpacerVertical large />
        <List>
          {voice.audio.map((audio) => {
            const isPlaying = player.status === 'playing' && player.uri === audio.uri
            const Icon = isPlaying ? PauseIcon : PlayIcon

            return (
              <ListItem
                onClick={() => {
                  togglePlay(audio.uri)
                }}
                icon={Icon}
                text={audio.name}
                secondaryText={audio.description}
              />
            )
          })}
        </List>
        <SpacerVertical huge />
      </Block>
    </Block>
  )
})

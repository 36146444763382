import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

let first = history.location.key

export const routeTo = (path, replace = false) => {
  if (replace) {
    history.replace(path, { from: history.location.pathname })
  } else {
    history.push(path, { from: history.location.pathname })
  }
}

export const goBack = (fallback = '/') => {
  if (history.location.key !== first) {
    history.goBack()
  } else {
    routeTo(fallback, true)
    first = history.location.key
  }
}

import { useEffect } from 'preact/hooks'
import { stringify } from 'querystring'

//defaultValues = { page: 0, query: '', orderBy: 'name', sortOrder: 'asc'}

export const useUrlParams = (params, defaultValues) => {
  useEffect(() => {
    const p = {}
    for (let [key, value] of Object.entries(params)) {
      if (value !== defaultValues[key]) {
        p[key] = value
      }
    }
    if (Object.keys(p).length > 0) {
      history.replaceState(null, null, location.pathname + '?' + stringify(p))
    } else {
      history.replaceState(null, null, location.pathname)
    }
  }, Object.values(params))
}

import { h } from 'preact'
import { Avatar as BongoAvatar } from '@sodra/bongo-ui'

import { getCropperUrl } from '../get-cropper-url'

const Avatar = ({ size = 50, src, ...rest }) => {
  const cropperUrl = src ? getCropperUrl({ url: src, width: size, height: size }) : undefined
  return <BongoAvatar src={cropperUrl} size={size} {...rest} />
}

export default Avatar

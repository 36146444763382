import { useEffect, useState } from 'preact/hooks'

import { get } from '../../api'
import { setError } from '../../actions'

export const useFetchVoices = (params = {}) => {
  const [isFetchingVoices, setIsFetchingVoices] = useState(true)
  const [voices, setVoices] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setIsFetchingVoices(true)
    let cancelled = false
    get('/voices', params)
      .then(({ data: voices, meta: { total } }) => {
        if (!cancelled) {
          setVoices(voices)
          setTotal(total)
          setIsFetchingVoices(false)
        }
      })
      .catch((err) => {
        if (!cancelled) {
          setError(err)
          setIsFetchingVoices(false)
        }
      })
    return () => {
      cancelled = true
    }
  }, Object.values(params))

  return {
    isFetchingVoices,
    voices,
    total
  }
}

import { Fragment, h } from 'preact'
import { SelectChip } from '@sodra/bongo-ui'

import { usePopupMenu } from '../use-popup-menu'

const soundsLikeOptions = [
  { value: 'child', label: 'Child' },
  { value: 'teen', label: 'Teen' },
  { value: 'young-adult', label: 'Young adult' },
  { value: 'middle-aged', label: 'Middle aged' },
  { value: 'senior', label: 'Senior' }
]

const formatSoundsLike = (value = '') => {
  for (let option of soundsLikeOptions) {
    if (option.value === value) {
      return option.label
    }
  }
  return ''
}

const SoundsLikeSelectChip = ({ value, onChange }) => {
  const { PopupMenu, showMenu } = usePopupMenu({
    options: soundsLikeOptions.map((option) => {
      return {
        label: option.label,
        onClick: () => onChange(option.value)
      }
    })
  })

  return (
    <Fragment>
      <SelectChip
        label="Sounds like"
        value={formatSoundsLike(value)}
        onClick={showMenu}
        onClear={() => onChange()}
      />
      {PopupMenu}
    </Fragment>
  )
}

export default SoundsLikeSelectChip

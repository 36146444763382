import { SelectChip } from '@sodra/bongo-ui'
import { Fragment, h } from 'preact'
import { usePopupMenu } from '../use-popup-menu'

const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1)

const formatGender = (gender) => capitalize(gender)

const GenderSelectChip = ({ value, onChange }) => {
  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      {
        label: 'Male',
        onClick: () => onChange('male')
      },
      {
        label: 'Female',
        onClick: () => onChange('female')
      }
    ]
  })

  return (
    <Fragment>
      <SelectChip
        label="Gender"
        value={formatGender(value)}
        onClick={showMenu}
        onClear={() => onChange()}
      />
      {PopupMenu}
    </Fragment>
  )
}

export default GenderSelectChip

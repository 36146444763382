import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import { ListItem, PauseIcon, PlayIcon, SpacerVertical } from '@sodra/bongo-ui'
import { parse } from 'querystring'
import { routeTo } from '@sodra/prutt'

import { store } from '../../store'
import { useUrlParams } from '../../use-url-params'
import { togglePlay } from '../../actions'

import Avatar from '../Avatar'
import SearchField from './SearchField'
import Logo from '../Logo'
import LoadingList from '../LoadingList'

import { useFetchVoices } from './use-fetch-voices'
import { Pager } from './Pager'

import GenderSelectChip from './GenderSelectChip'
import SoundsLikeSelectChip from './SoundsLikeSelectChip'
import LanguageSelectChip from './LanguageSelectChip'

const pageSize = 10

export const Voices = connect('player')(({ player }) => {
  const params = parse(location.search.slice(1))

  const [query, setQuery] = useState(params.query || '')
  const [language, setLanguage] = useState(params.language)
  const [gender, setGender] = useState(params.gender)
  const [soundsLike, setSoundsLike] = useState(params.soundsLike)
  const [page, setPage] = useState(parseFloat(params.page || '1'))

  useUrlParams({ query, language, gender, soundsLike, page }, { query: '', page: 1 })

  const { voices, isFetchingVoices, total } = useFetchVoices({
    onlyApproved: true,
    query,
    language,
    gender,
    soundsLike,
    page,
    pageSize
  })

  const pages = Math.ceil(total / pageSize)

  return (
    <Block padding="20px">
      <Block margin="20px auto" maxWidth="800px">
        <Logo />
        <SpacerVertical large />
        <SearchField value={query} onChange={setQuery} />
        <SpacerVertical />
        <Row alignItems="center" gap={10}>
          <LanguageSelectChip
            value={language}
            onChange={(language) => {
              setLanguage(language)
              setPage(1)
            }}
          />
          <GenderSelectChip
            value={gender}
            onChange={(gender) => {
              setGender(gender)
              setPage(1)
            }}
          />
          <SoundsLikeSelectChip
            value={soundsLike}
            onChange={(soundsLike) => {
              setSoundsLike(soundsLike)
              setPage(1)
            }}
          />
        </Row>
        <SpacerVertical large />
        <LoadingList loading={isFetchingVoices}>
          {voices.map((voice) => {
            const audio = voice.audio[0]
            const hasAudio = typeof audio !== 'undefined'
            const isPlaying = hasAudio && player.status === 'playing' && player.uri === audio.uri
            const Icon = isPlaying ? PauseIcon : PlayIcon

            return (
              <ListItem
                onClick={() => {
                  store.setState({ voice })
                  routeTo(`/voice/${voice.id}`)
                }}
                visual={<Avatar size="40" src={voice.picture} name={voice.name} />}
                text={voice.name}
                actionIcon={Icon}
                onActionClick={() => togglePlay(voice.audio[0]?.uri)}
                actionDisabled={!hasAudio}
              />
            )
          })}
        </LoadingList>
        <SpacerVertical />
        {pages > 1 && <Pager page={page} pages={pages} setPage={setPage} />}
        <SpacerVertical large />
      </Block>
    </Block>
  )
})

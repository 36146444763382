export const getCropperUrl = ({ url, width, height }) => {
  const dpr = window.devicePixelRatio || 1
  const params = {
    url,
    width: Math.floor(width * dpr),
    height: Math.floor(height * dpr)
  }
  const encoded = btoa(JSON.stringify(params))
  return `${process.env.CROPPER_URL}/${encoded}`
}
